@import './src/scss/variables';
@import './src/scss/app';

.tabata-step {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &-item {
    flex: 0 1 auto;
  }

  &-active {
    flex: 0 1 auto;
    font-weight: bold;
  }
}

.tabata-input-label {
  width: 100%;
  display: inline-block;
}

.tabata-input {
  display: inline-block;
  width: 50%;
  border: 1px solid black;
}

.tabata-input-button {
  display: inline-block;
}

.select__menu-item_sound {
  color: blue
}

.select__menu-item_null {
  display: none;
}

.modal__dialog {
  .modal__group {
    margin-bottom: 1rem;

    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem -10px;
    }

    &-col {
      padding: 5px 10px;
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
      }
    }

    &-title {
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      display: block;
    }

    label {
      cursor: pointer;
    }

    &-input {
      width: 100%;
      border: 1px solid $color;
      height: 30px;
      padding: 0 25px 0 8px;
      border-radius: 3px;

      input {
        width: 100%;
      }
    }

    &-timer_options {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      label {
        margin-right: 1rem;
      }
    }

    .select__search:focus ~ .select__arrow:before {
      transform: translateY(50%);
    }

    &.modal__group_alert {
      .modal__group-row {
        padding-left: 11px;
      }

      label {
        margin-right: 1rem;
      }
    }
  }

  .modal__content {
    max-height: 700px;
  }

  .select__search {
    outline: none;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.open-submenu {
  .moment-picker.inline {
    position: absolute;
    display: block;
  }
}

.set-timer__part {
  width: calc(100% / 3 - 3px);
  display: inline-block;

  .modal__group-title {
    font-size: inherit;
    font-weight: 400;
  }

  label {
    padding: 5px 0;
  }

  [select-directive] {
    .select {
      width: calc(100% / 3 - 20px);
    }

    .select__main {
      width: 100%;

      &-menu {
        max-height: 200px;
      }
    }
  }
}

set-timer-comp {
  .select-custom-sound {
    color: $color9;
  }

  .set-timer-play {
    line-height: 32px;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    padding: 0 15px;
  }
}

.set-timer {
  display: flex;

  &__select {
    display: flex;
    align-items: center;
  }

  &__play {
    margin-right: 1rem;
  }

  &__sound {
    display: flex;
    align-items: center;

    &_select {
      width: 280px;
      margin-right: 1rem;

      .select__main {
        width: 280px;
      }
    }
  }
}

.game {
  .nav__content-list li.has-submenu.open-submenu .nav__content-list_inner {
    right: initial;
    left: 0;
    transform: none;
  }

  &__timeparts {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &-icon {
      font-size: 60px;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }

  &__title {
    text-align: center;
  }

  &__text {
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 600;
  }
}

.timer__picker {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 170px;
  margin: 0 -10px;

  li {
    padding: 10px;
    width: 20%;
  }

  a {
    &:hover {
    }
  }
}

.timer__button {
  font-family: "Open Sans", sans-serif;
  font-size: $font-size;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;

  &[disabled],
  &:disabled {
    opacity: .7;
    cursor: not-allowed;
    pointer-events: none;
  }

  &_primary {
    color: $color;
    background: transparent;
    border: 1.5px solid $color;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none; // Reset unusual Firefox-on-Android default style; see github.com/necolas/normalize.css/issues/214
    padding: 0.375rem 1.5rem;
    border-radius: $border-radius2;
  }
}

.timer__buttons {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;

  .timer__button {
    margin-right: 1rem;
  }
}

.timer__date {
  font-size: 1.25rem;
  text-align: center;
  margin: 0 0 2rem;

  &::first-letter {
    text-transform: capitalize;
  }
}

.timeparts {
  display: flex;
  justify-content: center;
  margin: 0 0 2rem;

  &__item {
    text-align: center;
    display: flex;
    align-items: center;

    &:not(:last-child):after {
      content: ":";
      font-size: 36px;
      margin: 0 0.5rem;
    }

    &-count {
      min-width: 120px;
      font-size: 96px;
      line-height: 1;
      margin: 0.5rem;
      font-weight: 600;
    }

    &-format {
      font-size: 0.725rem;
      text-transform: uppercase;
    }
  }
}

.time__table {
  text-align: center;

  tr {
    border-bottom: 1px solid #e8e9ec;

    td,
    th {
      padding: 5px 0;
    }

    th {
      background: #e8e9ec;
    }

    &:hover {
      td {
        background: lightyellow;
      }
    }
  }
}

.alarm {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-bottom: 0.5rem;

    &-delimeter {
      font-size: 2rem;
      margin: 1rem;
      font-weight: 600;
    }

    span:not(.alarm__time-delimeter) {
      font-size: 96px;
      font-weight: 600;
    }
  }

  &__date {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__info {
    background: $color6;
    padding: 0.75rem 1rem;
    border-radius: $border-radius;
    margin-bottom: 1rem;

    strong {
      color: $color11;
    }
  }

  &__remaining {}
}

.moment-picker {
  &.inline .moment-picker-container {
    width: 19em;
  }

  .moment-picker-container {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    border-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    color: #888;
  }

  .header-view th {
    background: #fff;
    padding-top: 10px;
    padding-bottom: 8px;
    vertical-align: middle;

    &:nth-child(2) {
      font-weight: 500;
      font-size: 14px;

      &:hover {
        background: #fff;
      }
    }
  }

  .moment-picker-specific-views {
    padding: 0;

    table {
      border-collapse: separate;
      border-spacing: 7px 2px;
    }
  }

  .day-view td {
    height: 1.7em;
    vertical-align: middle;
    font-size: 15px;
  }

  .day-view {
    padding-left: 10px;
    padding-right: 10px;
    padding: 10px 15px 15px;
  }

  .month-view td {
    border-radius: 50%;
    padding: 5px 0;
    text-align: center;
    width: 33px;
    font-size: 14px;
  }

  th {
    font-size: 14px;
  }

  th:hover,
  td:hover {
    background: #eee;
  }

  td.selected {
    background: #0090dd;
  }

  .moment-picker-addition {
    text-align: center;
    padding: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #555;

    a {
      color: inherit;
    }
  }
}
